import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ReactDOM from 'react-dom';
import Root from './Root';
import './index.css';

ReactDOM.render(
<Root />,
document.getElementById('root')
);
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Autocomplete from  'react-autocomplete';
import { throttle, debounce } from 'throttle-debounce';
import axios from 'axios';
import './Search.css';
import Board from './Board';

class SearchContainer extends Component {

    state = {
        value: '',
        loading: false,
        stops: [],
        stop: null,
        board: null,
        app: null
    }

    constructor(mainComponent, props) {
        super(props);
        this.matchThrottled = throttle(200, this.matchSearch);
        this.matchDebounced = debounce(200, this.matchSearch);
        this.matchCache = {};
    }

	componentDidMount() {
	    this.searchStops.focus();
	}

    match(value) {
        this.setState({ value: value });

        if (value.length < 5) {
            this.matchThrottled(value);
        } else {
            this.matchDebounced(value);
        }
    }

    matchSearch(value) {
        if (value === '') {
            this.setState({ value: '', loading: false, stops: [], stop: null });
            return;
        }

        if (this.matchCache[value] != null) {
            this.setState({ stops: this.matchCache[value], stop: null });
            return;
        }

        var data = JSON.stringify({
            name: value,
            maxResultCount: 10
        })

        const CancelToken = axios.CancelToken;
        let cancel;

        if (this.state.loading && cancel != null) {
            cancel();
        }

        this.setState({ value: value, loading: true, stops: [], stop: null })

        axios.post("https://api.liwetrip.com/stop/api/match", data, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            headers: {
                'Content-Type': 'application/json',
                'X-device-access-key': 'UsCpn6QUYxR1Y0aYuDrq7AJt7Uoc6MMO',
                'X-app-access-key': '4ol9bdJoN3K8Jy7ZP16T80hEzGEtNKCp'
            }
        }).then((response) => {
            if (value !== this.state.value) {
                return;
            }

            this.matchCache[value] = response.data;
            this.setState({ loading: false, stops: response.data, stop: null })
        }).catch((error) => {
            this.setState({ loading: false, stops: [], stop: null })
        });
    }

	render() {
		return (
		    <div>
            <div className="row gtr-uniform aln-center">
                <div className="col-4">
                    <Autocomplete
                        ref={el => this.searchStops = el}
                        inputProps={{ id: 'searchStops', type: 'text', name: 'searchStops', value: 'test', placeholder: 'Sök stationer och hållplatser...'  }}
                        wrapperStyle={{}}
                        wrapperProps={{}}
                        items={ this.state.stops }
                        value={ this.state.value }
                        getItemValue={ item => item.stopName }
                        shouldItemRender={ this.matchStops }
                        onChange = {(event, value) => {
                            this.match(value);
                        }}
                        onSelect = {(value, item) => {
                            this.setState({ value, stop: item })
                        }}
                        renderInput={props => {
                            return <input {...props} onKeyPress={this.keyDown}/>;
                          }}
                        renderMenu={ (items, value) => (
                            <div className="col-4 autocomplete-menu">
                            { this.state.loading ? (
                                <div className="autocomplete-item">Hämtar...</div>) :
                            items }
                            </div>
                        )}
                        renderItem={ (item, isHighlighted) => (
                            <div
                                className={`item ${isHighlighted ? 'autocomplete-item-highlighted' : 'autocomplete-item'}`}
                                key={ item.stopId } >
                                { item.stopName }
                            </div>
                        )}
                    />
                </div>
                <div className="col-0">
                    <input type="button" value="" className="arrow" onClick={ this.search.bind(this) }/>
                </div>
            </div>
            <div>
                <a class="more scrolly" onClick={ this.showApp.bind(this) }>LiweTrip app</a>
            </div>
            </div>
		);
	}

    setBoard(board) {
        this.setState({board: board});
    }

    setApp(app) {
        this.setState({app: app});
    }

    matchStops(state, value) {
        return (
            state.stopName.toLowerCase().indexOf(value.toLowerCase()) !== -1
        );
    }

    keyDown = (e) => {
        if (this.state.stop != null && e.key === 'Enter') {
            e.preventDefault();
            this.doSearch();
        }
    }

    showApp(e) {
        e.stopPropagation();
        this.state.app.show();
    }

	search(e) {
	    e.stopPropagation();

        if (this.state.stop == null) {
            return;
        }

        this.doSearch();
    }

    doSearch() {
        this.matchCache = {};
        this.searchStops.blur();
        document.getElementById("board").style = "display: block;";
        this.state.board.search(this.state.stop);
    }

    clearSearch() {
        this.setState({ value: '', stops: []});
    }
}

export default SearchContainer;

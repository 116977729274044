import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Privacy.css';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

class PrivacyContainer extends Component {

    state = {

    }

	constructor(props) {
		super(props);
	}

	render() {
			return (
			  <section id="privacy" class="privacy wrapper style5">
			    <div class="inner">

                    <h2>LiweTrip's Privacy Policy</h2>

                    <p>
                        Valid from 24th November 2022.
                    </p>

                    <p>
                        This privacy policy describes what information we hold, why we hold it and how we use it. This policy applies to anyone who uses our
                        website <a href="https://www.liwetrip.com">www.liwetrip.com</a> (“Site”) and App (“LiweTrip App”). Collectively called "The App".
                    </p>

                    <h3>Collection of information</h3>

                    <h4>a. Registration</h4>

                    <p>
                        In the app we offer the possibility to register an account using Google Sign-In, Apple Sign-In or Facebook log-in. Registration gives
                        you the possibility to share information like stops and subscriptions across several instances of our App.
                    </p>

                    <h4>a. Personal information</h4>

                    <p>
                        If you choose to register we store your username, full name and email address. This information is stored as part of your LiweTrip
                        account.
                    </p>

                    <h4>b. Account deletion</h4>

                    <p>
                        You can delete your LiweTrip account at any time in the app under Settings -> Registration -> Delete account. When you delete your
                        LiweTrip account your username, full name and email address is removed. Your saved stops and subscriptions are not deleted but only
                        available in the App instance where they where created.
                    </p>

                    <h4>c. Subscriptions</h4>

                    <p>
                        Information about your subscriptions are stored in LiweTrips servers to enable the App to send you push notifications
                        about trips that yout monitor. Subscriptions are associated with your LiweTrip user account if you have created one.
                        Otherwise they are associated with an opaque identity of your App which is not associated with you as a person.
                    </p>

                    <h4>d. Direct communications</h4>

                    <p>
                        When you communicate with us (for example when you send emails, report problems, raise queries etc.) we may receive information about the type of
                        phone you're using, your service provider and, settings in your device. We use this information to fix problems in the App.
                    </p>

                    <h3>Created information</h3>

                    <p>
                        The following types of information is created when you use the App.
                    </p>

                    <h4>a. Device information</h4>

                    <p>
                        We collect device information you use to access the App, including the type of computer, device, hardware model, operating system,
                        preferred language and time zone settings. This information is needed by us to collect statistics, diagnose and improve the App.
                    </p>

                    <h4>b. Log and usage information</h4>

                    <p>
                        Each time you access the App we log information about how you use the App. We use this information for planning the App service,
                        fixing bugs, preventing crashes and keeping track of popular features.
                    </p>

                    <h4>c. Cookies</h4>

                    <p>
                        The App currently uses no cookies for storing information. This may change in subsequent releases of the App. In this case, this privacy policy
                        will be updated.
                    </p>

                    <h3>Other sources</h3>

                    LiweTrip uses the following information from other sources.

                    <p>
                        In order to pay for your LiweTrip subscription, you will need to provide payment information. Apple collect credit or debit card (“Payment Card”)
                        data with respect to in-app purchases of LiweTrip subscriptions.
                    </p>

                    <h4>Contact information</h4>

                    <p>
                        Please submit any questions, concerns or comments you have about this privacy policy or any requests concerning your personal data by email
                        to <a href="mailto:info@netdigital.se">info@netdigital.se</a>.
                    </p>
                </div>
              </section>
			)
	}
}

export default PrivacyContainer;

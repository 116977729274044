import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Terms.css';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

class TermsContainer extends Component {

    state = {

    }

	constructor(props) {
		super(props);
	}

	render() {
			return (
			  <section id="terms" class="terms wrapper style5">
			    <div class="inner">

                    <h2>LiweTrip Terms & Conditions</h2>

                    <p>LIWETRIP GENERAL TERMS OF SERVICE FOR iOS APP AND WEBSITE</p>

                    <p>
                        Last updated 26 March 2020.
                    </p>

                    <h3>Terms of use</h3>

                    <p>
                        Acceptance of the General Terms of Service. These general terms of service (the “Terms”) set out the agreement between you and NetDigital Sweden AB,
                        a company registered in Sweden under company number 556783-8791. NetDigital Sweden AB operates the
                         website <a href="https://www.liwetrip.com">www.liwetrip.com</a> (the “Site”) and the LiweTrip application.
                    </p>

                    <p>
                        In these Terms, both the Site and application shall be referred to as the "App".
                    </p>

                    <p>
                        By using the App, you agree to be bound by these Terms. If you do not agree to any part of these Terms, do not use the App.
                    </p>

                    <p>
                        Privacy. Your access to and use of the App is also subject to our Privacy Policy. By accepting these Terms of Service, you also confirm that you
                        have reviewed and understand our <a href="/privacy">Privacy Policy</a>, and you acknowledge that any information shared by, or collected from or
                        about, you may be used by LiweTrip in accordance with our <a href="/privacy">Privacy Policy</a>, as it is updated or changed from time to time.
                    </p>

                    <p>
                        If you are under 18 years old, you represent and agree that you have the legal consent of your parent or guardian to access the App.
                        If you are a parent or guardian of a minor who has registered for or used the App without your consent, please contact us
                        at <a href="mailto:info@netdigital.se">info@netdigital.se</a>.
                    </p>

                    <p>
                        You understand and agree that we may change these Terms at any time without prior notice. The revised Terms will become effective upon posting to the
                        App. Any use of the App after such date shall constitute your acceptance of such revised Terms. Please read these Terms carefully before using the
                        App. If you do not agree to these Terms, you do not have the right to use the App.
                    </p>

                    <h3>Warranty</h3>

                    <p>
                        The App and the information therein are provided "as is". We make no warranties, expressed or implied, and hereby disclaim any warrany,
                        including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement
                        of intellectual property or other violation rights. Further, we do not warrant or make any representations concerning the accuracy,
                        likely results, or reliability of the use of the information accessible through the App or otherwise relating to such information or on
                        any sites linked to the App.
                    </p>

                    <p>
                        We use third parties, such as Samtrafiken, Trafikverket and Apple to provide data on which the App's transport's information is based. We and our
                        data providers try to make sure that the data is correct and updated. The information may contain errors and we do not accept any liability for
                        any error or omission in the information available in the App, and we exclude all liability for any action you may take or loss or injury you may
                        suffer (direct or indirect including loss of income, profit, opportunity or time) as a result of relying on any information available through the App.
                    </p>

                    <p>
                        We offer no guarantee that the functions and information of the App will be provided without interruption or errors or that any mistakes or
                        errors will be corrected. We do not guarantee that the App or the servers through which they become available to users do not contain viruses
                        or other harmful components, although we make every effort to ensure it.
                    </p>

                    <p>
                        Making the App reliable and enjoyable means we need to fix bugs, install updates and do general diagnosis and maintenance. We will aim to do so at
                        what we anticipate will be non-peak times.
                    </p>

                    <h3>In-app purchase</h3>

                    <p>
                        LiweTrip subscription. If you purchase a LiweTrip subscription, these are the applicable payment and billing terms. By subscribing to LiweTrip, you
                        agree to the following terms (in addition to the rest of these Terms).
                    </p>

                    <p>
                        LiweTrip subscription is the paid version of our App with additional functionality for paid subscribers.
                    </p>

                    <p>
                        Fee changes
                    </p>

                    <p>
                        NetDigital Sweden AB may change the prices for the LiweTrip subscription from time to time and will notify you of any such price changes by email
                        or through information posted in the App. Price changes to LiweTrip subscription will take effect from the next monthly period following the
                        notification of the price change. As permitted by law, you accept the new price by continuing to use the LiweTrip subscription after the price
                        change takes effect. If you do not agree with the price changes, you have the right to reject the change by terminating the LiweTrip subscription
                        prior to the price change going into effect.
                    </p>

                    <p>
                        Termination or Cancellation of your LiweTrip subscription
                    </p>

                    <p>
                        Please note that your subscription may auto-renew and you may be charged unless you cancel more than 24 hours before your current subscription
                        expires. You may cancel at any time to ensure that your subscription does not automatically renew at the end of the current term. When you cancel,
                        you are cancelling the next billing charge. You will continue to have access to LiweTrip subscription features until the end of your current paid
                        subscription period, regardless of when you cancel the automatic renewal (i.e. you can cancel at any time after your subscription starts and continue
                        to access LiweTrip subscription features through the end of the monthly subscription period).
                    </p>

                    <p>
                        We do not issue refunds for termination or cancellation of your subscription. If you no longer wish to subscribe, you are responsible for cancelling
                        your subscription in due time, regardless of whether or not you actually use the features.
                    </p>

                    <p>
                        Subscriptions are purchased via our iOS app and are managed in your iTunes account controls. You can learn how to manage or cancel a recurring
                        subscription purchased on iOS <a href="https://support.apple.com/en-us/HT202039">here</a>.
                    </p>

                    <p>
                        Account security. Your account is personal to you, and you may not share your account information with, or allow access to your account by, any third
                        party. Because you will be responsible for all activity that happens under your access credentials, you agree to use reasonable efforts to prevent
                        unauthorised access to or use of your LiweTrip account, and to protect the confidentiality of your log-in information and of any device that you use
                        to access the App. You agree to let us know immediately if there is a breach in secrecy of your log-in information by notifying us
                        at <a href="mailto:info@netdigital.se"> info@netdigital.se</a>. You will be solely responsible for any losses incurred by us and others due to any
                        unauthorised use of your account.
                    </p>

                    <h3>Intellectual property</h3>

                    <p>
                        The App contain material, including but not limited to text, graphics, images and software (referred to as the “LiweTrip Content”). We may own the
                        LiweTrip Content or portions of the LiweTrip Content may be made available to us through agreements that we have with third parties.
                    </p>

                    <p>
                        Unauthorised use of the LiweTrip Content may result in violation of copyright, trademark, and other laws. You have no rights in or to the LiweTrip
                        Content, and you will not use, copy or display the LiweTrip Content except as permitted under these Terms. No other use is permitted without our
                        prior written consent. You must retain all copyright and other proprietary notices contained in the original LiweTrip Content on any copy you make
                        of the LiweTrip Content. You may not sell, transfer, assign, license, sublicense, or modify the LiweTrip Content or reproduce, display, publicly
                        perform, make a derivative version of, distribute, or otherwise use the LiweTrip Content in any way for any public or commercial purpose. The use
                        or posting of any of the LiweTrip Content on any other website or in a networked computer environment for any purpose is expressly prohibited. If
                        you violate any part of these Terms, your right to access and/or use of the LiweTrip Content and App shall automatically terminate and you shall
                        immediately destroy any copies you have made of the LiweTrip Content.
                    </p>

                    <h3>Unauthorized use</h3>

                    <p>
                        You agree that you will not use or attempt to use any method, device, software or routine to harm others or interfere with functioning of the
                        App or use and/or monitor any information in or related to the App for any unauthorized purpose. Specifically, each user, whether authorized or
                        unauthorized, agrees that it will not (and will not permit any of its employees, agents, and any other third party to) do any of the following:
                    </p>

                    <ul>
                        <li>
                            Use the App or any information contained therein or provide, link, or otherwise make available, any information on the App that is unlawful,
                            threatening, abusive, harassing, torturous, defamatory, vulgar, obscene, libellous, invasive of another’s privacy, hateful, harmful to minors
                            or racially, ethnically or otherwise objectionable.
                        </li>
                        <li>
                            Manipulate, forge, or otherwise modify information or identifiers in a manner which may hide or disguise the origin of any information.
                        </li>
                        <li>
                            Modify, decompile, reverse engineer or hack into any of the software applications related to the App.
                        </li>
                        <li>
                            Interfere with the operation of the App including, but not limited to, by distribution of unsolicited advertising or mail messages or
                            propagation of worms, viruses and the like. Alter or delete information not provided by you.
                        </li>
                        <li>
                            Impersonate any person or entity, or otherwise misrepresent your affiliation.
                        </li>
                        <li>
                            You also agree not to permit any other user of the App to access, view, download or reproduce the material for any use not permitted by
                            us.
                        </li>
                    </ul>

                    <p>
                        NetDigital Sweden AB does not claim ownership of any text, images, photos, video, sounds or any other materials (collectively,
                        to NetDigital Sweden AB a non-exclusive, royalty-free, worldwide and irrevocable license to use, modify, delete from, add to, publicly perform,
                        publicly display, reproduce and translate such Submissions, including without limitation for promoting and redistributing part or all of the
                        LiweTrip application (and derivative works thereof) in any media formats and through any media channels (including, without limitation, third party
                        websites and feeds). NetDigital Sweden AB can continue to use your Submissions in these ways if you cease to use the LiweTrip App. You further agree
                        that NetDigital Sweden AB has the right to delete or otherwise edit any Submissions that you post to the LiweTrip App at its sole discretion.
                    </p>

                    <h3>Liability limitation</h3>

                    <p>
                        NetDigital Sweden AB, makes no warranties or representations about the content of the App and the underlying software, including but not
                        limited to its accuracy, reliability, completeness or timeliness. NetDigital Sweden AB shall not be subject to liability for the truth, accuracy or
                        completeness of the content or any other information conveyed to the user, or for errors, mistakes or omissions therein or for any delays or
                        interruptions of the data or information stream from whatever cause. You agree that you use the App and the Content at your own risk.
                    </p>

                    <p>
                        Unless caused by our gross negligence or intent, we shall in no event be liable for any losses or damages, whether direct or indirect (including,
                        without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the App or the use or inability to
                        use the content of the App, even if we have been notified of the possibility of such damage.
                    </p>

                    <p>
                        Whilst NetDigital Sweden AB will monitor the App and aim to fix bugs that it spots or that are made known to us, NetDigital Sweden AB do not warrant
                        that the App or underlying software will operate error free or that the App, its server, or the content are free of computer viruses or bugs or
                        similar contamination or destructive features. If your use of the App or the content results in the need for servicing or replacing equipment or
                        data, NetDigital Sweden AB shall not be responsible for those costs.
                    </p>

                    <p>
                        The App and Content are provided on an “as is” and “as available” basis without any warranties of any kind. NetDigital Sweden AB disclaim all
                        warranties, whether express or implied, and including, but not limited to, merchantability, non-infringement of third-party rights, and fitness for
                        particular purpose.
                    </p>

                    <p>
                        You agree to defend, indemnify, and hold harmless NetDigital Sweden AB from and against any claims, actions or demands, including,
                        without limitation, reasonable legal and accounting fees, arising or resulting from your breach of these Terms or your access to, use or misuse of the
                        LiweTrip Content and/or the App. NetDigital Sweden AB shall provide notice to you of any such claim, suit, or proceeding.
                        NetDigital Sweden AB reserves the right to assume the exclusive defense and control of any matter which is subject to indemnification under
                        this section. In such case, you agree to cooperate with any reasonable requests assisting NetDigital Sweden AB's defence of such matter.
                    </p>

                    <h3>Law and jurisdiction</h3>

                    <p>
                        These Terms will be governed by Swedish law. Any dispute arising from, or related to, these Terms shall be subject to the
                        non-exclusive jurisdiction of the district courts of Sweden.
                    </p>
                </div>
              </section>
			)
	}
}

export default TermsContainer;

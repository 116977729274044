import React, {Component} from 'react';
import './App.css';
import ReactDOM from 'react-dom';
import './index.css';
import Search from './Search';
import App from './App';
import Board from './Board';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

class MainContainer extends Component {

    state = {
        search: null,
        board: null,
        app: null
    }

	constructor(props) {
		super(props);
		this.searchRef = React.createRef();
		this.boardRef = React.createRef();
		this.appRef = React.createRef();
	}

    componentDidMount() {
        this.searchRef.current.setBoard(this.boardRef.current);
        this.searchRef.current.setApp(this.appRef.current);
        this.boardRef.current.setSearch(this.searchRef.current);
    }

	render() {
			return (
			         <div>
			            <section id="search">
                            <Search ref={this.searchRef} />
                        </section>

                        <section id="board" style={{display: 'none'}} class="board wrapper style5">
                            <Board ref={this.boardRef} />
                        </section>

                        <section id="app">
                            <App ref={this.appRef} />
                        </section>
			         </div>
			       )
	}

    setSearch(search) {
        this.setState({ search: search });
    }
}

export default  MainContainer;

import React, {Component} from 'react';
import './App.css';
import axios from 'axios';

class AppContainer extends Component {

    state = {

    }

	constructor(props) {
		super(props);
	}

	render() {
			return ( <div class="row">
			            <div class="liwetripapp col-6"></div>
			            <div class="liwetripinfo col-6">
			                <ul class="large">
			                    <li>Appen för dig som pendlar med kollektivtrafik dagligen.</li>
			                    <li>Schemalägg bevakningar av dina dagliga avgångar.</li>
			                    <ul class="sublarge">
			                        <li>Molnbaserad realtidsbevakning.</li>
			                        <li>Skickar notifieringar med information.</li>
			                        <li>Skickar notifieringar vid avvikelser.</li>
			                    </ul>
			                    <li>Täcker följande kollektivtrafik</li>
			                    <ul class="sublarge">
			                        <li>All tågtrafik i hela Sverige.</li>
			                        <li>SL (Stockholms lokaltrafik)</li>
			                        <li>UL (Upplands lokaltrafik)</li>
			                        <li>Östgötatrafiken (Norrköping och Linköping)</li>
			                        <li>Skånetrafiken</li>
			                        <li>Västgötatrafiken (Göteborg)</li>
			                    </ul>
			                </ul>
			                <div class="row aln-center">
			                    <a class="appstore" href="https://apps.apple.com/se/app/liwetrip/id1201641715"></a>
			                </div>
			            </div>
			         </div> )
	}

	show() {
        var Scroll = require('react-scroll');
        var scroller = Scroll.scroller;
        scroller.scrollTo("app", { duration: 1000, delay: 0, smooth: true });
    }
}

export default  AppContainer;

import React, {Component} from 'react';
import ReactTable from 'react-table'
import moment from 'moment';
import './Board.css';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import PageVisibility from 'react-page-visibility';

class BoardContainer extends Component {

    state = {
        loading: false, // Whether or not to display the loader. Only done on first search for a board
        scrolling: false, // Whether or not container is currently being scrolled
        searching: false, // Whether or not a request is currently being made to the backend.
        searchCountdown: 0, // Counter to countdown to 0 for executing interval search.
        previousSearchCountdown: 0, // The previous search countdown used.
        visible: true, // Whether or not the tab/page is currently in focus. No searches executed when not in focus.
        stop: null, // Id of stop or station that board is displayed for.
        board: [], // The list of trips returned by the backend.
        filteredBoard: [], // The list of trips to be displayed filtered based on route types, arrivals/departures.
        arrival: false, // Whether or not to display arrivals.
        departure: true, // Whether or not to display departures.
        boardNames: {
            'BUS': 'Buss',
            'RAIL': 'Tåg',
            'TRAM': 'Spårvagn',
            'SUBWAY': 'Tunnelbana',
            'FERRY': 'Båt',
            'TAXI': 'Taxi',
            'FUNICULAR': 'Linbana',
            'CABLE_CAR': 'Linbana',
            'GONDOLA': 'Gondol'
        },
        boardTypes: [], // Unique route types for board trips returned by backend.
        boardTypeStates: {}, // States route types for display on board.
        search: null
    }

	constructor(props) {
		super(props);
		this.boardRef = React.createRef()
        this.handleArrival = this.handleArrival.bind(this);
        this.handleDeparture = this.handleDeparture.bind(this);
	}

	componentDidMount() {
        var Scroll = require('react-scroll');
        var Events = Scroll.Events;
        var self = this;

        Events.scrollEvent.register('end', function(to, element) {
            if (self.state.scrolling == true) {
                self.doSearch(true);
                self.setState({scrolling: false});
                self.state.search.clearSearch();
            }
        });
	}

    componentWillUnmount() {
        if (this.intervalId != null) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    setSearch(search) {
        this.setState({search: search});
    }

    handleVisibilityChange = isVisible => {
        this.setState({ visible: isVisible, previousSearchCountdown: 5 });

        if (isVisible === true) {
            this.doSearch(false);
        } else if (this.intervalId != null) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    renderCheckboxes() {
        if (this.state.boardTypes.length < 2) {
            return;
        }

        var boardTypes = this.state.boardTypes;
        var boardTypeStates = this.state.boardTypeStates;

        return boardTypes
            .map((type, index) =>
                <span key={type}>
                    <input
                        id={type}
                        name={type}
                        type="checkbox"
                        checked={boardTypeStates[type]}
                        onChange={this.toggleCheckbox.bind(this, index)}
                    />
                    <label htmlFor={type}>{this.state.boardNames[type]}</label>
                </span>
            );
    }

    toggleCheckbox(index) {
        var type = this.state.boardTypes[index];
        var boardTypeStates = this.state.boardTypeStates;
        boardTypeStates[type] = !boardTypeStates[type];
        this.setState({boardTypeStates: boardTypeStates});
        this.doFilter(this.state.board, this.state.arrival, this.state.departure);
    }

    handleArrival(event) {
        this.doFilter(this.state.board, true, false);
    }

    handleDeparture(event) {
        this.doFilter(this.state.board, false, true);
    }

	render() {
	    if (this.state.loading === true) {
            return ( <Loader type="Bars" color="#0c75ac" height="100" width="100"/> );
	    } else if (this.state.board.length === 0) {
            return ( "" );
        } else {
            const columns = [{
                id: 'name',
                Header: 'Namn',
                accessor: update => {
                    return update.trips[0].tripName;
                }
            }, {
                id: 'time',
                Header: () => (
                        this.state.departure === true ? 'Avgång' : 'Ankomst'
                ),
                headerClassName: 'rt-th-center',
                Cell: row => (
                    <span>
                        { row.value.delay === 'minor' || row.value.delay === 'major' || row.value.delay === 'before' ?
                        <span>
                            <span className="obsolete-time">{row.value.time}</span>
                            { row.value.delay === 'before' ?
                            <span className="before-time">{row.value.estimatedTime}</span> :
                            row.value.delay === 'minor' ?
                            <span className="minor-delay-time">{row.value.estimatedTime}</span> :
                            <span className="major-delay-time">{row.value.estimatedTime}</span>
                            }
                        </span>
                        :
                        row.value.delay === 'unknown' ?
                        <span>{row.value.time}</span>
                        :
                        row.value.delay === 'canceled' ?
                        <span>
                            <span className="obsolete-time">{row.value.time}</span>
                            <span className="canceled-time">Inst.</span>
                        </span>
                        :
                        <span className="on-time">{row.value.time}</span>
                        }
                    </span>
                ),
                className: 'rt-td-center',
                accessor: update => {
                        return this.state.departure === true ?
                            this.doFormatTime(update.departureTime, update.estimatedDepartureTime, update.canceled) :
                            this.doFormatTime(update.arrivalTime, update.estimatedArrivalTime, update.canceled);
                },
                width: 90
            }, {
                id: 'tripHeadsign',
                Header: () => (
                        this.state.departure === true ? 'Till' : 'Från'
                ),
                accessor: update => {
                        return this.state.departure === true ?
                            update.trips[0].tripHeadsign :
                            update.trips[0].tripOrigin;
                }
            }, {
                id: 'platformCode',
                Header: 'Läge',
                headerClassName: 'rt-th-center',
                accessor: 'platformCode',
                className: 'rt-td-center',
                width: 60
            }];

            return (<PageVisibility onChange={this.handleVisibilityChange}>
                        <div className="col-4">
                            <h4>{this.state.stop.stopName}</h4>
                            <form>
                                <input type="radio" id="arrival" name="arrival_departure" value="arrival" checked={this.state.arrival === true} onChange={this.handleArrival} />
                                <label htmlFor="arrival">Ankomsttider</label>
                                <input type="radio" id="departure" name="arrival_departure" value="departure" checked={this.state.departure === true} onChange={this.handleDeparture} />
                                <label htmlFor="departure">Avgångstider</label>
                                {this.renderCheckboxes.call(this)}
                            </form>
                            <ReactTable
                                showPagination={false}
                                noDataText=''
                                defaultPageSize={100}
                                sortable={false}
                                resizable={false}
                                minRows={0}
                                loading={false}
                                loadingText={""}
                                data={this.state.filteredBoard}
                                columns={columns} />
                        </div>
                    </PageVisibility>);
        }
	}

    search(stop) {
        if (this.intervalId != null) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }

        this.setState({stop: stop, loading: true, searchCountdown: 0, previousSearchCountdown: 5});
        var Scroll = require('react-scroll');
        var scroller = Scroll.scroller;
        this.setState({scrolling: true})
        scroller.scrollTo("board", { duration: 1000, delay: 0, smooth: true });
    }

    doFormatTime(time, estimatedTime, canceled) {
        var mom = moment(time);
        var momFormatted = mom.local().format("HH:mm");

        if (canceled === true) {
            return { time: momFormatted, delay: 'canceled' };
        }

        if (estimatedTime != null) {
            var estimatedMom = moment(estimatedTime);
            var estimatedMomFormatted = estimatedMom.local().format("HH:mm");

            if (estimatedMomFormatted !== momFormatted) {
                var min = mom.hour() * 60 + mom.minute();
                var estimatedMin = estimatedMom.hour() * 60 + estimatedMom.minute();
                var delay = "unkown";

                if (estimatedMin < min) {
                    delay = "before";
                } else if (estimatedMin - min < 5) {
                    delay = "minor";
                } else {
                    delay = "major";
                }

                return { estimatedTime: estimatedMomFormatted, time: momFormatted, delay: delay };
            }

            return { time: momFormatted, delay: 'none' };
        }

        var momFormatted = moment(time).local().format("HH:mm");
        return { time: momFormatted, delay: 'unknown'};
    }

    updateSearch() {
        if (this.state.visible === true && this.state.searching === false) {
            if (this.state.searchCountdown > 0) {
                var countdown = this.state.searchCountdown;
                countdown--;
                this.setState( { searchCountdown: countdown });
                return;
            }

            if (this.state.previousSearchCountdown == 0) {
                this.setState( { previousSearchCountdown: 5, searchCountdown: 5 });
            } else {
                this.setState( { previousSearchCountdown: 0, searchCountdown: 0 });
            }

            this.doSearch(false);
        }
    }

	doSearch(loading) {
	    this.setState({ searching: true });
	    var url = "https://api.liwetrip.com/stop/api/";
	    var stop = this.state.stop;

	    if (stop.locationType === "STATION") {
            url += "station/" + stop.stopId + "/board";
	    } else {
            url += "stop/" + stop.stopId + "/board";
	    }

        const CancelToken = axios.CancelToken;
        let cancel;

        if (cancel != null) {
            cancel();
        }

        axios.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            headers: {
               'X-device-access-key': 'UsCpn6QUYxR1Y0aYuDrq7AJt7Uoc6MMO',
                'X-app-access-key': '4ol9bdJoN3K8Jy7ZP16T80hEzGEtNKCp'
             }
        }).then((response) => {
            cancel = null;
            this.doBoardTypes(response.data, loading);

            if (this.intervalId == null) {
                this.intervalId = setInterval(this.updateSearch.bind(this), 10000);
            }

            this.doFilter(response.data, this.state.arrival, this.state.departure);
            this.setState({ searching: false });
        }).catch((error) => {
            cancel = null;
            console.log("Failed loading board");
            if (loading === true) {
                this.setState({ board: [], filteredBoard: [], loading: false });
            }

            this.setState({ searching: false });
        });
	}

    doBoardTypes(board, loading) {
        var self = this;
        var boardTypes = [];
        var boardTypeStates = {};

        board.forEach(function(item, index, array) {
            if (boardTypeStates[item.type] == null) {
                boardTypes.push(item.type);
                boardTypeStates[item.type] = true;
            }
        });

        if (loading === false) {
            var previousBoardTypeStates = this.state.boardTypeStates;

            if (this.hasUnsetBoardTypes(previousBoardTypeStates) === true) {
                for (var key in boardTypeStates) {
                    if (previousBoardTypeStates[key] === null || previousBoardTypeStates[key] === false) {
                        boardTypeStates[key] = false;
                    }
                }
            }
        }

        this.setState({boardTypes: boardTypes, boardTypeStates: boardTypeStates});
    }

    hasUnsetBoardTypes(boardTypeStates) {
        for(var key in boardTypeStates) {
            if (boardTypeStates[key] === false) {
                return true;
            }
        }

        return false;
    }

	doFilter(board, arrival, departure) {
        var filteredBoard = [];
        var self = this;

        board.forEach(function(item, index, array) {
            var show = self.state.boardTypeStates[item.type];

            if (show === true && departure === true) {
                if (item.pickupType === 'REGULAR' || item.pickupType === 'COORDINATE') {
                    filteredBoard.push(item);
                }
            } else if (show === true && arrival === true) {
                if (item.dropOffType === 'REGULAR' || item.dropOffType === 'COORDINATE') {
                    filteredBoard.push(item);
                }
            }
        });

        if (departure === true) {
            filteredBoard.sort(function(item1, item2) {
                var departureTime1 = moment(item1.departureTime);
                var departureTime2 = moment(item2.departureTime);

                if (departureTime1 >= departureTime2) {
                    return 1;
                } else {
                    return -1;
                }
            });
        } else {
            filteredBoard.sort(function(item1, item2) {
                var arrivalTime1 = moment(item1.arrivalTime);
                var arrivalTime2 = moment(item2.arrivalTime);

                if (arrivalTime1 >= arrivalTime2) {
                    return 1;
                } else {
                    return -1;
                }
            });
        }

        this.setState({board: board, filteredBoard: filteredBoard, arrival: arrival, departure: departure, loading: false});
	}
}

export default  BoardContainer;
